import router from "@/router";
import { getError } from "@/utils/helpers";
import AuthService from "@/services/AuthService";
import { saveStorage, getStorage, clearStorage } from '@/services/StorageManager';

export const namespaced = true;

export const state = {
  user: null,
  loading: false,
  error: null,
};

export const mutations = {
  SET_USER( state, user ) {
    state.user = user;
  },
  SET_LOADING( state, loading ) {
    state.loading = loading;
  },
  SET_ERROR( state, error ) {
    state.error = error;
  },
};

export const actions = {
  logout( { commit, dispatch } ) {
    commit( "SET_LOADING", true );
    commit( "SET_USER", null );
    clearStorage( 'auth' )
    dispatch( "setGuest", { value: "isGuest" } );
    commit( "SET_LOADING", false );
    if( router.currentRoute.name !== "login" ) {
      router.push( { path: "/login" } );
    }
  },
  async getAuthUser( { commit, dispatch }, userData ) {
    commit( "SET_LOADING", true );
    try {
      const response = await AuthService.getAuthUser( userData );
      commit( "SET_USER", response.data.data );
      saveStorage( 'auth', { user: response.data.data } )
      commit( "SET_LOADING", false );
      if( ! response.data.data.isAdmin ){
        dispatch('logout')
      }
      return response.data.data;
    }
    catch( error ) {
      commit( "SET_LOADING", false );
      commit( "SET_USER", null );
      commit( "SET_ERROR", getError( error ) );
    }
  },
  setGuest( context, { value } ) {
    window.localStorage.setItem( "guest", value );
  },
  userPersistent ( { commit } ){
    const user = getStorage( 'auth', 'user' )
    commit( "SET_USER", user );
  }
};

export const getters = {
  authUser: ( state ) => {
    getStorage
    return state.user;
  },
  isAdmin: ( state ) => {
    return state.user ? state.user.isAdmin : false;
  },
  error: ( state ) => {
    return state.error;
  },
  loading: ( state ) => {
    return state.loading;
  },
  loggedIn: ( state ) => {
    return !! state.user;
  },
  guest: () => {
    const storageItem = window.localStorage.getItem( "guest" );
    if( ! storageItem ) {
      return false;
    }
    if( storageItem === "isGuest" ) {
      return true;
    }
    if( storageItem === "isNotGuest" ) {
      return false;
    }
  },
};
