export default function auth( { to, next, store } ) {
  const loginQuery = { path: "/login", query: { redirect: to.fullPath } };
  store.dispatch('auth/userPersistent');

  if( ! store.getters[ 'auth/authUser' ] ) {
    next( loginQuery );
  }
  else {
    next();
  }
}
