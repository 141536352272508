<template>
    <div>
        <nav class="navbar navbar-expand-lg navbar-light bg-light ps-4">
            <router-link to="/" class="navbar-brand">Inicio</router-link>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse d-flex justify-content-end" id="navbarSupportedContent">
                <ul class="navbar-nav mr-auto">
                    <li class="nav-item" v-if="! authUser || ! authUser.name">
                        <router-link to="/login" class="nav-link">Login</router-link>
                    </li>
                    <template v-else>
                      <li class="nav-item">
                        <router-link to="/prints" class="nav-link">Lista de impresiones</router-link>
                      </li>
                      <li class="nav-item">
                        <div class="nav-link">
                          {{ authUser ? authUser.name : '' }}
                        </div>
                      </li>
                      <li class="nav-item">
                          <a class="nav-link" href="#" @click="logoutUser">Logout</a>
                      </li>
                    </template>
                </ul>
            </div>
        </nav>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
    name: "Header",
    computed: {
        ...mapGetters('auth', [
            'authUser'
        ])
    },
  methods: {
    logoutUser() {
      this.$store.dispatch( 'auth/logout' );
    }
  }
}
</script>

<style scoped>

</style>
