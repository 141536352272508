<template>
  <div id="app">
    <Header />
    <main class="container mx-auto h-100">
      <router-view />
    </main>
  </div>
</template>

<script>
import Header from "@/components/Header";

export default {
  name: "app",
  components: {
    Header,
  },
};
</script>

<style>
</style>
