import { createApp } from 'vue'

import 'vuetify/styles'
import '@mdi/font/css/materialdesignicons.css'

import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'

import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

import App from './App.vue'
import router from './router'
import store from './store'

import { createVuetify } from 'vuetify'
import { loadFonts } from '@/plugins/webfontloader';

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

import VueClipboard from 'vue3-clipboard'

const vuetify = createVuetify()
loadFonts()

createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(ElementPlus)
  .use(VueSweetalert2)
  .use(VueClipboard, {
    autoSetContainer: true,
    appendToBody: true,
  })
  .mount('#app')
