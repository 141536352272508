import store from "@/store/index";
import { createRouter, createWebHistory } from 'vue-router'
import auth from "@/middleware/auth";
import login from "@/middleware/login";
import middlewarePipeline from "@/router/middlewarePipeline";

const routes = [
    {
        path: "/",
        name: "home",
        meta: { middleware: [ auth ] },
        component: () => import(/* webpackChunkName: "home" */ "../views/Dashboard"),
    },
    {
        path: "/prints",
        name: "prints",
        meta: { middleware: [ auth ] },
        component: () => import(/* webpackChunkName: "home" */ "../views/Prints"),
    },
    {
        path: "/generado-ok",
        name: "generadoOk",
        component: () => import(/* webpackChunkName: "home" */ "../views/UserGeneratedOk.vue"),
    },
    {
        path: "/generado-ko",
        name: "generadoKo",
        component: () => import(/* webpackChunkName: "home" */ "../views/UserGeneratedKo.vue"),
    },
    {
        path: "/login",
        name: "login",
        component: () => import(/* webpackChunkName: "home" */ "../views/Login"),
    },
    {
        path: "/:catchAll(.*)",
        name: "notFound",
        component: () =>
            import(/* webpackChunkName: "not-found" */ "../views/NotFound"),
    },
];

const router = createRouter( {
    history: createWebHistory( process.env.BASE_URL ),
    routes,
    scrollBehavior( to, from, savedPosition ) {
        if( savedPosition ) {
            return savedPosition;
        }
        else {
            return { x: 0, y: 0 };
        }
    },
} );

router.beforeEach( ( to, from, next ) => {
    const middleware = to.meta.middleware;
    const context = { to, from, next, store };

    if( ! middleware ) {
        return next();
    }

    middleware[ 0 ]( {
        ...context,
        next: middlewarePipeline( context, middleware, 1 ),
    } );
} );

export default router;
